<template>
	<div class="fill-height">
		<v-overlay>
			<v-progress-circular 
				indeterminate 
				size="96"
			>
				Loading...
			</v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"
import { mapGetters, mapActions } from "vuex"

export default {
	mixins: [ 
		errorHandlerMixin
	],
	data() {
		return {
			title: process.env.MIX_APP_NAME,
			user:{
				name : null,
				email : null,
				password : null,
				confirm_password : null,
				role_id : process.env.VUE_APP_CUSTOMER_ROLE_ID,
			},
			errors : {},
			error_else: null,
			isLoading : false,
			show_pass: false,
		}
	},
	computed: {
		...mapGetters(["redirectAfterLogin"]),
	},
	mounted() {
		//Mount the method to be called natively on the window 
		window.login = this.login
		window.oauth2_verify = this.oauth2_verify
		window.login_guest = this.login_guest
	},
	created(){
		// check is logined
		if(this.$auth.check()){
			this.redirectUser(this.$auth.user().role_id)
		}
		// await this.axios.get({ url: '/sanctum/csrf-cookie', baseURL: '/' }) // reuqired for cross-domain
	},
	methods:{
		login(details = null){
			this.errors = {}
			this.error_else = null
			this.isLoading = true;

			this.$auth.login({
				data: details ?? {
					email: this.user.email,
					password: this.user.password,
					dkWebLogin: true,
				},
				staySignedIn: true,
				fetchUser: true,
			}).then((res)=>{
				let fetchUserResult = res.data.data
				this.redirectUser(fetchUserResult.role_id)
			}).catch((err)=>{
				console.log(err)
				window.postMessage("login_error")
				window.parent.postMessage("login_error")
				this.$toast.warning('Invalid login details')
			}).finally(()=>{
				this.isLoading = false
			});
		},
		sign_up(){
			this.errors = {}
			this.error_else = null
			this.isLoading = true;
			if(this.user.password == this.user.confirm_password)
			{
				this.$auth.register({
					data: {
						name: this.user.name,
						email: this.user.email,
						password: this.user.password,
						role_id: this.user.role_id,
					},
					staySignedIn: true,
					fetchUser: true,
				}).then(()=>{
					this.$toast.success('Sign up successfully.')
					this.$router.push("/login")
				}).catch((err)=>{
					this.errors = this.errorHandler_(err, ['name', 'email', 'password'])
				}).finally(()=>{
					this.isLoading = false
				});
			}
			else
				this.$toast.warning('Password and confirm password value mismatch')
			
			this.isLoading = false

			
		},
		login_oauth(type){
			this.errors = {}
			this.isLoading = true;

			this.$auth.oauth2(type, {
				params: {
					redirect_uri: 'login/'+type,
					response_type: 'code,token',
					state: {
						remember: false,
						staySignedIn: true,
						fetchUser: true,
					},
				},
				window: {
					name: '_blank',
					specs: {},
					replace: false
				},
			}).then((res)=>{
				let fetchUserResult = res.data.data
				this.redirectUser(fetchUserResult.role_id)
			}).catch((err)=>{
				window.postMessage("login_error")
				window.parent.postMessage("login_error")
				this.$toast.warning('Something went wrong.')
			}).finally(()=>{
				this.isLoading = false
			});
		},
		oauth2_verify(type, token){
			this.$auth.oauth2(type, {
				url: 'auth/'+type+'/login',
				code: true,
				data: {
					access_token: token
				},
				state: this.$route.query.state,
			}).then((res)=>{
				let fetchUserResult = res.data.data
				this.redirectUser(fetchUserResult.role_id)
			}).catch((err)=>{
				console.log(err)
				window.postMessage("login_error")
				window.parent.postMessage("login_error")
				this.$toast.warning('Something went wrong.')
			}).finally(()=>{
				this.isLoading = false
			});
		},
		redirectUser(role_id = null){
			let home = {
				"3" : "/new-order",
			}

			if(this.redirectAfterLogin)
				return this.$router.push("/new-order/customization")
			else
			{
				if(home[role_id])
					return this.$router.push(home[role_id])
				
				return this.$router.push("/order-histories")
			}
		},
		oauth_apple()
		{
			document.getElementById('appleid-signin').click()
		},
		callSuccess(data)
		{
			this.$auth.oauth2('apple', {
				url: 'auth/apple/login',
				code: true,
				data: {
					access_token: data.authorization.id_token
				},
				state: 'STATE',
			}).then((res)=>{
				let fetchUserResult = res.data.data
				this.redirectUser(fetchUserResult.role_id)
			}).catch((err)=>{
				console.log(err)
				this.$toast.warning('Something went wrong.')
			}).finally(()=>{
				this.isLoading = false
			});
		},
		callFail(data)
		{
			this.$toast.warning('Something went wrong.')
		},
		login_guest(){
			this.login({
				email: "developer@dk-schweizer.com",
				password: "BsjXL94fIXZn887OhFsfgQ",
			})
		},
	}
}
</script>